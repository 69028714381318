<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
 Add Video

       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addFormvalidate">
     <b-form class="mt-2" style="width: 100%" @submit.prevent="save" ref="form">
        <b-row>
         <b-col md="6">
            <b-form-group
              label="Link"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="Link"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addForm.link"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
        
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            
          </b-col>
        </b-row>
      </b-form>

    </validation-observer>
   <video-list ref="table"/>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import VideoList from "./VideoList.vue";
import store from '@/store'
import Vue from "vue";

export default {
  setup() {
 
    // const store = useStore();
  const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
     const Tabimage = ref("media/svg/files/blank-image.svg");
    const categoriesList=ref([])
    const table=ref()
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
  
 store.dispatch('categories/AllCategory')
        .then(response => {
          console.log("response",response.data.data)
          categoriesList.value=response.data.data
        })
    const addForm = reactive({
      link: "",
      
     
    });
    const form=ref()
  
     const addFormvalidate=ref()

    const save = () => {
      
      console.log(addForm)
      const formData = new FormData();

    
        
         addFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("video", addForm.link);
          formData.append("order", 1);


           store.dispatch('gallery/addVideo',formData)
        .then(response => {
            table.value.refetchData()
             form.value.reset()
           
                
           Vue.swal({
                       title: "Video Added ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
      console.log("alt_header_image", addForm);
        }})
    };

    return {
    
      addForm,
         refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
   
      addFormvalidate,
      categoriesList,
      
     table,
form,
      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
    BTable,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
  VideoList,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mt-50.col-12 {
    margin-bottom: 12px !important;
}
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }
  
  </style>